import { observable } from 'mobx';
import { ChartModel, IChartModel } from './models/chartModel';

class ChartModelsStore {
    charts = observable<IChartModel>([]);

    getOrCreateChartModel = (chartId: string) => {
        let chart = this.charts.find(chart => chart.chartId === chartId);
        if (chart === undefined) {
            chart = new ChartModel(chartId);
            this.charts.push(chart);
        }
        return chart;
    }
}

export const chartModelsStore = new ChartModelsStore();
