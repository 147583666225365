import { action, makeObservable, observable } from 'mobx';


class FacePageStore {
    selectedSecurity: string | undefined;

    constructor() {
        makeObservable(this, {
            selectedSecurity: observable,
            setSelectedSecurity: action
        })
    }

    setSelectedSecurity = (newSelectedSecurity: string) => {
        this.selectedSecurity = newSelectedSecurity;
    }
}

export const facePageStore = new FacePageStore();