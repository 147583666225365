import { useGlobalDataContext } from '../../contexts/globalDataContext';
import { useStores } from '../../hooks/useStores';
import { useMemo } from 'react';
import { ISignalMeta } from '../../stores/applicationStore';
import { HistoryEntry } from '../../apis/bbsignals';

export const useAssetHistory = (securityName: string | undefined): (HistoryEntry & {signalMeta: ISignalMeta})[] => {
    const { bbSignals } = useGlobalDataContext();
    const { applicationStore } = useStores();
    const asset = bbSignals.find(a => a.security === securityName);
    const memo = useMemo(() => {
        if (asset === undefined) {
            return [];
        }
        return asset.history.map(entry => ({...entry, signalMeta: applicationStore.getSignalProperties(entry.signal)}))
    }, [asset, applicationStore])

    return memo;
}