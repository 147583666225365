import log from 'loglevel';

let messages: string[] = [];
let originalFactory = log.methodFactory;

function toString(arg: unknown) {
    if (typeof arg === 'object') {
        return JSON.stringify(arg);
    }
    return arg;
}
log.methodFactory = function (methodName, logLevel, loggerName) {
    let rawMethod = originalFactory(methodName, logLevel, loggerName);

    return function (...args) {
        let message = args.map(toString).join(' ');
        messages.push(methodName.toUpperCase() + ': ' + message);
        rawMethod(...args);
    };
};
log.setLevel('INFO'); // Be sure to call setLevel method in order to apply plugin

let originalOnError = window.onerror;
window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
    if (originalOnError) {
        return originalOnError(errorMsg, url, lineNumber);
    }
    log.error(errorMsg, url, lineNumber);
    // set error handled
    return true;
};

window.onpopstate = function (event: PopStateEvent) {
    log.info('navigate to ', event.state);
};

log.info("loglevel's config is set");

function getMessages() {
    return messages.slice();
}

export { getMessages };
