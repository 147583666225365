import React from 'react';
import { Switch, Route } from 'react-router';

import Settings from './components/Settings';
import {FacePage} from './components/FacePage/FacePage';
import {SecurityPage} from './components/SecurityPage/SecurityPage';
import Help from './components/Help';
import urls from './services/urls';
import { TopVideos } from './components/TopVideos/TopVideos';

function Routes() {
  return (
    <Switch>
      <Route path={urls.settings} component={Settings} />
      <Route path={urls.help} component={Help} />

      <Route
        path={urls.securityPage}
        render={(props) => <SecurityPage key={props.match.params.securityName} />}
      />
        <Route
            path={urls.topVideos}
            render={() => <TopVideos />}
        />
      <Route path={urls.root} component={FacePage} exact />
    </Switch>
  );
}

export default Routes;
