import { SignalType } from '../../apis/bbsignals';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react';

export const SignalLabel = observer(({signal}: {signal: SignalType | undefined}) => {
    const { applicationStore } = useStores();
    if (signal === undefined) {
        return null;
    }
    const signalMeta = applicationStore.getSignalProperties(signal);
    return <span style={{color: signalMeta.color}}>{signalMeta.fullName}</span>
})