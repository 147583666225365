import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import terminal from 'blp/bb-apps-terminal';

const useStyles = makeStyles((theme) => ({
    input: {
        margin: theme.spacing(0.5),
        height: 28,
        fontSize: '0.8rem'
    },
    blpButton: {
        backgroundColor: '#1BBB3C',
        fontWeight: 'normal',
        color: 'black',
        borderRadius: '1px',
        minWidth: '54px'
    }
}));

const TerminalButton = ({
                            command,
                            security,
                            name,
                            tooltip
                        }: {
    command: string;
    security: string;
    name: string;
    tooltip: string;
}) => {
    const classes = useStyles();

    const runTerminalFunction = async (command: string) => {
        await terminal.runFunction(command, 1, security);
    };

    return (
        <Tooltip title={tooltip}>
            <Button
                onClick={() => runTerminalFunction(command)}
                className={`${classes.input} ${classes.blpButton}`}>
                {name}
            </Button>
        </Tooltip>
    );
};

export const TerminalCommands = ({security = "AAPL US Equity"} : {security?: string}) => {
    return <>
        <TerminalButton
            command="BQ"
            name="BQ"
            security={security}
            tooltip="Bloomberg Quote"
        />
        <TerminalButton
            command="CN"
            name="CN"
            security={security}
            tooltip="Individual Company News"
        />
        <TerminalButton
            command="DES"
            name="DES"
            security={security}
            tooltip="Security Description"
        />
        <TerminalButton
            command="EE"
            name="EE"
            security={security}
            tooltip="Earnings & Estimates"
        />
        <TerminalButton
            command="GP"
            name="GP"
            security={security}
            tooltip="Line Chart"
        />
    </>;
}
