import React from 'react';
import { Container, Typography } from '@material-ui/core';

function Help() {
    return (
        <Container>
            <Typography color="textPrimary" variant="h5">
                When Signal has a date click on Signal to view the historical performance
            </Typography>
            <Typography color="textPrimary" variant="body1">
                Orange “Moving to Boom”: Medium Probability of a Down Correction
            </Typography>
                <Typography color="textPrimary" variant="body1">
                Red “BOOM”: High Probability of a Down Correction
            </Typography>
            <Typography color="textPrimary" variant="body1">
                Blue “Moving to Bust”: Medium Probability for a Recovery
            </Typography>
            <Typography color="textPrimary" variant="body1">
                Green “BUST”: High Probability for a Recovery
            </Typography>
            <Typography color="textPrimary" variant="body1">
                Blue Bust - Orange Boom 60 - 65%
            </Typography>
            <Typography color="textPrimary" variant="body1">
                *Probability of a 3.5 - 7% Correction
            </Typography>
            <Typography color="textPrimary" variant="body1">
                Green Bust - Red Boom 80 - 85%
            </Typography>
                <Typography color="textPrimary" variant="body1">
                *Probability of a 10 - 20% Correction
            </Typography>
            <Typography color="textPrimary" variant="body1">
                Neutral 50/50
            </Typography>
            <Typography color="textPrimary" variant="body1">
                Blue and Orange are reset after a 3.5 to 7% correction we do NOT always issue a NEUTRAL signal as we do not want to manage your portfolio same for Green and Red reset after a 10 to 20% Correction.
            </Typography>
            <Typography> </Typography>
            <Typography color="textPrimary" variant="h5">
                Probability of a correction may change depending on asset class volatility
            </Typography>
            <Typography> </Typography>
            <Typography color="textPrimary" variant="h5">
                The matrix and signals are an indicator of an asymmetry of the risk profile
            </Typography>
            <Typography color="textPrimary" variant="body1">
                Boom: The risk to the down side is greater than the risk to the upside
            </Typography>
            <Typography color="textPrimary" variant="body1">
                Bust: The risk to the upside is greater than the risk to the downside
            </Typography>
        </Container>
    );
}

export default Help;
