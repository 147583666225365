import React from 'react';
import { fetchCompaniesHistoricalData } from 'services/blpData';
import moment from 'moment';
import { AnnotatedChart } from './AnnotatedChart';
import { Periodicity } from 'blp/bb-apps-data';
import { useQuery } from 'react-query';
import {
    Box,
    Card, CardContent,
    CardHeader,
    createStyles,
    Theme,
    Typography
} from '@material-ui/core';
import SearchSecurity from '../Shared/SelectSecurity';
import { makeStyles } from '@material-ui/core/styles';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react';
import { useAssetHistory } from './useAssetHistory';


interface SecurityChartProps {
    chartId: string;
    securityName: string | undefined;
}

type Candle = {
    date: string;
    open: number;
    low: number;
    high: number;
    close: number;
    volume: number;
}

type ChartPoint = {
    date: string;
    lastPrice: number;
}

async function fetchOHLC(security: string, startDate: moment.Moment | null, endDate: moment.Moment | null) {
    if (startDate === null || endDate === null) return [];
    let historyData = await fetchCompaniesHistoricalData(
        [security],
        ['PX_OPEN', 'PX_LOW', 'PX_HIGH', 'PX_LAST', 'PX_VOLUME'],
        startDate,
        endDate,
        Periodicity.Daily
    );
    let chartData: Candle[] = historyData[0].data.map((f, i) => ({
        date: f.date,
        open: f.PX_OPEN,
        low: f.PX_LOW,
        high: f.PX_HIGH,
        close: f.PX_LAST,
        volume: f.PX_VOLUME
    }));
    return chartData;
}

async function fetchLastPrices(security: string, startDate: moment.Moment | null, endDate: moment.Moment | null) {
    if (startDate === null || endDate === null) return [];
    let historyData = await fetchCompaniesHistoricalData(
        [security],
        ['PX_LAST'],
        startDate,
        endDate,
        Periodicity.Daily
    );
    let chartData: ChartPoint[] = historyData[0].data.map((f, i) => ({
        date: f.date,
        lastPrice: f.PX_LAST,
    }));
    return chartData;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'grid',
            gridTemplateRows: '1fr',
            height: '100%',
            maxHeight: '100%',
            maxWidth: '100%'
        },
        toolbarPanel: {
            '& > *': {
                margin: theme.spacing(1)
            },
        },
    }),
);

export const SecurityChart = observer(({chartId, securityName}: SecurityChartProps) => {
    const classes = useStyles();
    const { chartModelsStore, applicationStore } = useStores();
    const model = chartModelsStore.getOrCreateChartModel(chartId);
    const globalChartSettings = applicationStore.globalSettings;

    const assetHistory = useAssetHistory(securityName);

    const ohlcQuery = useQuery(['pricesQuery', securityName, globalChartSettings.startDate?.toString(), globalChartSettings.endDate?.toString()],
        async () => await fetchOHLC(securityName as string, globalChartSettings.startDate, globalChartSettings.endDate));
    const comparePriceQuery = useQuery(['comparePriceQuery', model.compareSecurity, globalChartSettings.startDate?.toString(), globalChartSettings.endDate?.toString()],
        async () => await fetchLastPrices(model.compareSecurity, globalChartSettings.startDate, globalChartSettings.endDate),
        {enabled: !!model.compareSecurity});

    if (ohlcQuery.isLoading) {
        return null;
    }

    if (!ohlcQuery.isSuccess) {
        return null;
    }

    if (!assetHistory) {
        return <Typography>Unknown asset {securityName}</Typography>;
    }

    return <Card style={{flex: '1 100%'}}>
        <CardHeader title={`${securityName} History Price`}
                    action={<Box pt={0.6}><SearchSecurity initialSecurity={model.compareSecurity}
                                                 onSecuritySelected={model.setCompareSecurity} /></Box>} />
        <CardContent  style={{height: 'calc(100% - 48px)'}}>
            <div className={classes.container}>
                <div style={{overflow: 'hidden'}}>
                    <AnnotatedChart seriesName={securityName} data={ohlcQuery.data}
                                    compareChart={comparePriceQuery.data}
                                    asset={assetHistory}
                                    chartType={globalChartSettings.chartType}
                                    chartLabelType={globalChartSettings.chartLabelType} />
                </div>
            </div>
        </CardContent>
    </Card>;
});
