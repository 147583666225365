import { makeObservable, observable, action } from 'mobx';

export class ThemeStore {
    theme: string = 'dark';

    constructor() {
        makeObservable(this, {
            theme: observable,
            setTheme: action
        });
    }

    setTheme(newTheme: string) {
        this.theme = newTheme;
    }
}
