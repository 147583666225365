import { useEffect } from 'react';
import { useLocation } from 'react-router';
import log from 'loglevel';

export default function LogRoutes() {
    let location = useLocation();
    useEffect(() => {
        log.info('navigate to', location.pathname);
    }, [location]);

    return null;
}
