import Data, {
    IBlpapiDatetime,
    IDataSession,
    IErrorInfo,
    IFieldException,
    IHistoricalSecurityData,
    ISecurityData,
    Periodicity
} from 'blp/bb-apps-data';
// @ts-ignore
import { BlpapiDatetime } from 'blp/bb-apps-data';
import log from 'loglevel';
import { blpIndicatorStore } from 'stores/blpIndicatorStore';

let defaultSession: IDataSession | null, sessionCreatePromise: Promise<IDataSession> | null;

async function createSession() {
    let session = await Data.createSession();
    session.addMessageReceiver(['SESSION_STATUS'], function* () {
        let msg: any;
        while (!!(msg = yield)) {
            let meta = Object.getOwnPropertySymbols(msg)[0];
            console.log('Got a message', msg);
            console.log(msg[meta].get('eventType'), msg[meta].get('messageType'));

            let messageType = msg[meta].get('messageType');
            if (messageType === 'SessionTerminated') {
                defaultSession = null;
                sessionCreatePromise = null;
                blpIndicatorStore.setConnected(false);
                setTimeout(getDefaultSession, 5000);
            }
            if (messageType === 'SessionStarted') {
                blpIndicatorStore.setConnected(true);
            }
        }
    });

    return session;
}

// async singleton
async function getDefaultSession() {
    if (!defaultSession) {
        if (!sessionCreatePromise) {
            sessionCreatePromise = createSession();
        }
        defaultSession = await sessionCreatePromise;
    }

    return defaultSession;
}

setTimeout(getDefaultSession, 0);

export type SearchFieldResultType = {
    id: string;
    mnemonic: string;
    description: string;
    datatype: string;
};
function searchFieldsBlpDataMapping(data: ISecurityData): SearchFieldResultType[] {
    return data.fieldData.map((d: any) => ({
        id: d.id,
        mnemonic: d.fieldInfo.mnemonic,
        description: d.fieldInfo.description,
        datatype: d.fieldInfo.datatype
    }));
}

async function searchFields(searchTerm: string) {
    log.info(`Searching fields, searchTerm=${searchTerm}`);
    let session = await getDefaultSession();
    let result: SearchFieldResultType[] = [];
    let request: any = {
        searchSpec: searchTerm,
        returnFieldDocumentation: false
    };
    await session.request(
        '//blp/apiflds',
        'FieldSearchRequest',
        request,
        // eslint-disable-next-line no-cond-assign
        function* () {
            let data;
            while ((data = yield)) {
                result.push(...searchFieldsBlpDataMapping(data));
            }
        }
    );

    return result;
}

let yellowKeys = [
    { yellowKey: '<muni>', description: ' Muni' },
    { yellowKey: '<govt>', description: ' Govt' },
    { yellowKey: '<corp>', description: ' Corp' },
    { yellowKey: '<equity>', description: ' Equity' },
    { yellowKey: '<index>', description: ' Index' },
    { yellowKey: '<mtge>', description: ' Mtge' },
    { yellowKey: '<pfd>', description: ' Pfd' },
    { yellowKey: '<cmdty>', description: ' Cmdty' },
    { yellowKey: '<mmkt>', description: ' Mmkt' },
    { yellowKey: '<crncy>', description: ' Curncy' }
];

function overrideYellowKey(security: string) {
    let yellowKey = yellowKeys.find((k) => security.endsWith(k.yellowKey));
    if (!yellowKey) {
        console.warn('Security without yellow key', security);
    }
    return yellowKey ? security.replace(yellowKey.yellowKey, yellowKey.description) : security;
}

export type SearchSecurityResultType = { security: string; descrption: string };

async function searchSecurities(
    searchTerm: string,
    maxResults = 100
): Promise<SearchSecurityResultType[]> {
    log.info(`Searching securities, searchTerm=${searchTerm}`);
    let messages: any[] = [];
    let session = await getDefaultSession();
    let request: any = {
        query: searchTerm,
        yellowKeyFilter: `YK_FILTER_NONE`,
        languageOverride: `LANG_OVERRIDE_NONE`,
        maxResults: maxResults
    };
    await session.request(
        '//blp/instruments',
        'instrumentListRequest',
        request,
        // eslint-disable-next-line no-cond-assign
        function* () {
            let data;
            while ((data = yield)) {
                messages.push(data);
            }
        }
    );
    let result = messages[0].results.map((item: any) => ({
        security: overrideYellowKey(item.security),
        description: item.description
    }));
    return result;
}

export type SecurityResponseType = {
    security: string;
    data: any;
    error: IErrorInfo | undefined;
    fieldExceptions: IFieldException[] | undefined;
};
function securityDataToResponse(securityData: ISecurityData): SecurityResponseType {
    return {
        security: securityData.security,
        data: securityData.fieldData,
        error: securityData.securityError,
        fieldExceptions: securityData.fieldExceptions
    };
}

async function fetchSecuritiesFieldData(
    securities: string[],
    fields: string[]
): Promise<SecurityResponseType[]> {
    let session = await getDefaultSession();

    let refData = await session.getReferenceData(securities, fields);
    let securityDatas = refData.reduce(
        (result: ISecurityData[], d) => result.concat(d.securityData),
        []
    );
    return securityDatas.map((d) => securityDataToResponse(d));
}

async function fetchSecurityFieldData(security: string, fields: string[]): Promise<any> {
    let response = await fetchSecuritiesFieldData([security], fields);
    if (response[0].error) {
        log.error(response[0].error);
        throw Error('Unknown security');
    }
    return response[0].data;
}

function toBlpapiDatetime(moment_datetime: moment.Moment): IBlpapiDatetime {
    return new BlpapiDatetime(
        moment_datetime.year(),
        moment_datetime.month() + 1,
        moment_datetime.date(),
        moment_datetime.hours(),
        moment_datetime.minutes(),
        moment_datetime.seconds()
    );
}

function blpDatetimeToDate(blpapiDatetime: IBlpapiDatetime): Date {
    if (blpapiDatetime.hours || blpapiDatetime.minutes || blpapiDatetime.seconds) {
        return new Date(
            blpapiDatetime.year,
            blpapiDatetime.month - 1,
            blpapiDatetime.day,
            blpapiDatetime.hours,
            blpapiDatetime.minutes,
            blpapiDatetime.seconds
        );
    }

    return new Date(blpapiDatetime.year, blpapiDatetime.month - 1, blpapiDatetime.day);
}

export type HistoryResponseType = {
    security: string;
    data: any[];
    error: IErrorInfo | undefined;
    fieldExceptions: IFieldException[] | undefined;
};
function historySecurityDataToResponse(securityData: IHistoricalSecurityData): HistoryResponseType {
    let data = securityData.fieldData.map((fieldData) => ({
        ...fieldData,
        date: blpDatetimeToDate(fieldData.date)
    }));
    return {
        security: securityData.security,
        data,
        error: securityData.securityError,
        fieldExceptions: securityData.fieldExceptions
    };
}

async function fetchCompaniesHistoricalData(
    securities: string[],
    fields: string[],
    startDate: moment.Moment,
    endDate: moment.Moment,
    periodicity = Periodicity.Monthly
): Promise<HistoryResponseType[]> {
    let session = await getDefaultSession();
    let end = toBlpapiDatetime(endDate);
    let start = toBlpapiDatetime(startDate);
    log.info(
        `fetching company historical data`,
        securities,
        fields,
        start.toString(),
        end.toString(),
        periodicity
    );
    let historyData = await session.getHistoricalData(
        securities,
        fields,
        start as any,
        end as any,
        periodicity
    );
    // todo: revise blp data processing.
    let securityDatas = historyData.reduce(
        (result: IHistoricalSecurityData[], d) => result.concat(d.securityData),
        []
    );
    console.log('fetched data', securityDatas, securityDatas.map((d) => historySecurityDataToResponse(d)));
    return securityDatas.map((d) => historySecurityDataToResponse(d));
}

async function fetchCompanyHistoricalData(
    security: string,
    fields: string[],
    startDate: moment.Moment,
    endDate: moment.Moment,
    periodicity = Periodicity.Monthly
) {
    return (
        await fetchCompaniesHistoricalData([security], fields, startDate, endDate, periodicity)
    )[0].data;
}

export type PortfolioDescriptionType = { id: number; name: string };

async function fetchPortfolioList(maxIdToCheck = 50): Promise<PortfolioDescriptionType[]> {
    let session = await getDefaultSession();
    let portfolioIds = Array.from({ length: maxIdToCheck }, (x, i) => i + 1).map(
        (i) => i + ' Client'
    );

    let data = await session.getPortfolioData(portfolioIds, ['PORTFOLIO_NAME']);
    let securityDatas = data.reduce(
        (result: ISecurityData[], d) => result.concat(d.securityData),
        []
    );
    let portfolios = securityDatas
        .filter((d: any) => !d.securityError)
        .map((d: any) => ({
            id: parseInt(d.security.replace(' Client', '')),
            name: d.fieldData.PORTFOLIO_NAME
        }))
        .sort((a, b) => a.id - b.id);
    return portfolios;
}

async function fetchPortfolio(prtuId: string): Promise<[string, string[]]> {
    log.info(`fetching portfolio from PRTU: ${prtuId}`);
    let session = await getDefaultSession();

    let data = await session.getPortfolioData(
        [prtuId + ' Client'],
        ['PORTFOLIO_NAME', 'PORTFOLIO_MPOSITION']
    );
    let securityData = data[0].securityData[0];
    let portfolioName = securityData.fieldData.PORTFOLIO_NAME;
    let securities = securityData.fieldData.PORTFOLIO_MPOSITION.map((p: any) => p.Security);
    return [portfolioName, securities];
}

export {
    getDefaultSession,
    searchFields,
    searchSecurities,
    fetchSecurityFieldData,
    fetchSecuritiesFieldData,
    fetchCompanyHistoricalData,
    fetchCompaniesHistoricalData,
    fetchPortfolioList,
    fetchPortfolio
};
