import { searchSecurities, SearchSecurityResultType } from '../../services/blpData';
import React, { useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import { useQuery } from 'react-query';

type SearchSecurityProps = {
    initialSecurity: string;
    onSecuritySelected: (security: string) => void;
};

function SearchSecurity({ initialSecurity, onSecuritySelected }: SearchSecurityProps) {
    let initialSelectedSecurity: SearchSecurityResultType | null = initialSecurity
        ? { security: initialSecurity, descrption: '' }
        : null;

    const [selectedSecurity, setSelectedSecurity] = useState(initialSelectedSecurity);
    const [open, setOpen] = useState(false);
    const [searchString, setSearchString] = useState('');
    const searchStringDebounced = useDebounce(searchString, 500);

    const searchSecuritiesQuery = useQuery(['searchSecuritiesQuery', searchStringDebounced],
        async () => await searchSecurities(searchStringDebounced),
        { enabled: !!searchStringDebounced })

    function handleSelectedSecurityChange(newSecurity: SearchSecurityResultType | null) {
        setSelectedSecurity(newSecurity);
        if (onSecuritySelected) {
            onSecuritySelected(newSecurity?.security ?? '');
        }
    }

    return (
        <Autocomplete<SearchSecurityResultType>
            style={{ width: 250 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={selectedSecurity}
            onChange={(e, newValue) => handleSelectedSecurityChange(newValue)}
            inputValue={searchString}
            onInputChange={(e, value, reason) => setSearchString(value)}
            getOptionSelected={(option, value) => option.security === value.security}
            getOptionLabel={(option) => option.security}
            filterOptions={(options) => options}
            options={searchSecuritiesQuery.data ?? []}
            loading={searchSecuritiesQuery.isLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Compare"
                    variant='outlined'
                    size="small"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {searchSecuritiesQuery.isLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    );
}

export default SearchSecurity;