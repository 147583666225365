import React, { Fragment, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip
} from '@material-ui/core';

import { getMessages } from 'logging/loglevelConfig';
import log from 'loglevel';
import { Assignment } from '@material-ui/icons';

function Log() {
    const [open, setOpen] = useState(false);
    const [logMessages, setLogMessages] = useState<string[]>([]);

    const handleClickOpen = () => {
        log.info(`Open log dialog`);
        setLogMessages(getMessages);
        setOpen(true);
    };

    const handleClose = () => {
        log.info(`Close log dialog`);
        setOpen(false);
    };

    function handleRefresh() {
        setLogMessages(getMessages);
    }

    return (
        <Fragment>
            <Tooltip title="Show application log" aria-label="showLog">
                <IconButton onClick={handleClickOpen}>
                    <Assignment />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
                <DialogTitle>Log</DialogTitle>
                <DialogContent>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Button onClick={handleRefresh} color="primary">
                                Refresh
                            </Button>
                        </Grid>
                    </Grid>
                    {logMessages.map((m, i) => (
                        <div key={i}>{m}</div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default Log;
