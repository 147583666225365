import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Toolbar,
    AppBar,
    Box,
    IconButton,
    Tooltip
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { HelpOutlineOutlined, Settings as SettingsIcon } from '@material-ui/icons';

import Log from './Log';
import { MainTabs } from './MainTabs';
import { TerminalCommands } from './TerminalCommands';
import Settings from './Settings';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar
    },
    title: {
        flexGrow: 1,
        display: 'block'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#ED1C24',
        color: 'white'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        height: '100vh',
        minHeight: '100vh',
        width: '100vw',
        backgroundColor: theme.palette.background.default
    },
    toolbar: theme.mixins.toolbar
}));

function Layout(props: React.PropsWithChildren<unknown>) {
    const classes = useStyles();
    const [openSettings, setOpenSettings] = useState(false);

    return (
      <div className={classes.root}>
          <AppBar position="fixed" className={classes.appBar}>
              <Toolbar style={{minHeight: 48}}>
                  <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                      Boom Bust Signals of Asymmetrical Risk/Reward
                  </Typography>

                  <Tooltip title="Show application help" aria-label="showHelp">
                      <IconButton onClick={() => setOpenSettings(true)}>
                          <SettingsIcon />
                      </IconButton>
                  </Tooltip>
                  <Log />
                  <Tooltip title="Show application help" aria-label="showHelp">
                      <IconButton component={Link} to="/help">
                          <HelpOutlineOutlined />
                      </IconButton>
                  </Tooltip>
              </Toolbar>
          </AppBar>
          <Box display="flex" flexDirection="column" className={classes.content}>
              <Box>
                  <div className={classes.appBarSpacer} />
                  <Box ml={3}>
                      <TerminalCommands />
                  </Box>
                  <Box ml={3} mb={1}>
                      <MainTabs />
                  </Box>
              </Box>
              <Box display="flex" flexDirection="column" flex={2} style={{overflow: 'auto'}}>
                  {props.children}
              </Box>
          </Box>
          {openSettings && <Settings open={openSettings} setOpen={setOpenSettings} />}
      </div>
    );
}

export default Layout;
