import React, { useContext } from 'react';
import { AssetDescription, fetchSignals } from '../apis/bbsignals';
import { LinearProgress, Typography } from '@material-ui/core';
import { useQuery } from 'react-query';


interface GlobalDataContextInterface {
    bbSignals: AssetDescription[];
}

export const GlobalDataContext = React.createContext<GlobalDataContextInterface | undefined>(undefined);

export const GlobalDataContextProvider = ({ children }: React.PropsWithChildren<unknown>) => {
    const signalsQuery = useQuery(['fetchSignals'], async () => await fetchSignals());


    if (signalsQuery.isError) {
        return <Typography>Failed to load signals. Try again later.</Typography>;
    }

    if (signalsQuery.isLoading) {
        return <LinearProgress />;
    }

    if (!signalsQuery.isSuccess) {
        return null;
    }

    const state: GlobalDataContextInterface = {
        bbSignals: signalsQuery.data
    };

    return <GlobalDataContext.Provider value={state}>{children}</GlobalDataContext.Provider>;
};

export const useGlobalDataContext = (): GlobalDataContextInterface => {
    const contextValue = useContext(GlobalDataContext);
    if (!contextValue) {
        throw Error('MainCompanyContext has not been Provided!');
    }
    return contextValue as GlobalDataContextInterface;
}