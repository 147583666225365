import React, { useMemo } from 'react';
import {
    Box, Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, InputLabel, MenuItem, Select,
    TextField, Theme,
    Typography
} from '@material-ui/core';
import { useStores } from '../hooks/useStores';
import { observer } from 'mobx-react';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { ChartLabelType, ChartType } from '../stores/applicationStore';
import { createViewModel } from 'mobx-utils';

type SettingsProps = {
    open: boolean,
    setOpen: (openState: boolean) => void;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        signalEditor: {
            display: 'grid',
            gridTemplateColumns: 'auto 200px 200px',
            gridGap: theme.spacing(1),
            marginLeft: theme.spacing(1),
            '& > *': {
                alignSelf: 'center'
            },
        },
        rangeEditor: {
            '& > *': {
                margin: theme.spacing(1)
            },
        },
    }),
);

const Settings = observer(({open, setOpen}: SettingsProps) => {
    const classes = useStyles();
    const { applicationStore } = useStores();
    const signals = useMemo(() => applicationStore.signalProperties.map(props => createViewModel(props)), [applicationStore]);
    const model = useMemo(() => createViewModel(applicationStore.globalSettings), [applicationStore.globalSettings]);

    function handleCancel() {
        setOpen(false);
    }

    function handleSave() {
        signals.forEach(signal => signal.submit());
        model.submit();
        setOpen(false);
    }

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Settings</DialogTitle>
            <DialogContent>
                <Typography>
                    ASSET PRICE CHART
                </Typography>

                <Box className={classes.rangeEditor} display="flex" flexDirection="row" marginRight="80px" alignItems="bottom">
                    <DatePicker label="from" value={model.startDate} onChange={(newDate) => model.setStartDate(newDate)} format="MM/DD/yyyy"
                                disableFuture={true} maxDate={model.endDate?.clone().add({day: -1})}
                                TextFieldComponent={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        style={{width: '120px'}}
                                    />
                                )}
                    />
                    <DatePicker label="to" value={model.endDate} onChange={(newDate) => model.setEndDate(newDate)} format="MM/DD/yyyy" disableFuture={true}
                                TextFieldComponent={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        style={{width: '120px'}}
                                    />
                                )}/>
                    <FormControl size="small">
                        <InputLabel></InputLabel>
                        <Select style={{width: '200px'}} value={model.chartType}
                                onChange={(e) => model.setChartType(e.target.value as ChartType)} >
                            <MenuItem value={ChartType.Line}>Line</MenuItem>
                            <MenuItem value={ChartType.Candle}>Japanese Candle</MenuItem>
                            <MenuItem value={ChartType.OHLC}>OHLC</MenuItem>
                    </Select>
                    </FormControl>
                </Box>

                <Box py={1} />
                <Typography>
                    SIGNAL FORMAT
                </Typography>
                <Box py={1} />
                <Select style={{width: '200px'}} value={model.chartLabelType} onChange={(e) => model.setChartLabelType(e.target.value as ChartLabelType)}>
                    <MenuItem value={ChartLabelType.ShortName}>Short Name</MenuItem>
                    <MenuItem value={ChartLabelType.FullName}>Long Name</MenuItem>
                </Select>

                <Box py={1} />
                <div className={classes.signalEditor}>
                    <Typography component="span">Signal</Typography>
                    <Typography component="span">Short Name</Typography>
                    <Typography component="span">Long Name</Typography>

                    {signals.map(signal => (
                        <React.Fragment key={signal.signal}>
                            <Typography component="span" style={{color: signal.color}}>{signal.signal}</Typography>
                            <TextField value={signal.shortName} onChange={(e) => signal.shortName = e.target.value}></TextField>
                            <TextField value={signal.fullName} onChange={(e) => signal.fullName = e.target.value}></TextField>
                        </React.Fragment>
                    ))}
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleSave} variant="outlined" color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
});

export default Settings;
