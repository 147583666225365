import React from 'react';
import { ThemeStore } from 'stores/themeStore';
import { blpIndicatorStore } from 'stores/blpIndicatorStore';
import { chartModelsStore } from 'stores/chartModelsStore';
import { facePageStore } from 'stores/facePageStore';
import { applicationStore } from '../stores/applicationStore';

export const storesContext = React.createContext({
    blpIndicatorStore,
    themeStore: new ThemeStore(),
    chartModelsStore,
    facePageStore,
    applicationStore
});
