import React, { Fragment } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';

import './App.css';
import './components/Layout';
import Layout from './components/Layout';
import './logging/loglevelConfig';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import LogRoutes from './logging/LogRoutes';
import Routes from './Routes';
import GlobalApplication from 'blp/bb-apps-application';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GlobalDataContextProvider } from './contexts/globalDataContext';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const theme = createTheme({
    palette: {
        type: 'dark',
        primary: { main: '#ED1C24' },
        text: { primary: '#ffffff', secondary: 'orange'}
    },
    overrides: {
        MuiCardHeader: {
            root: {
                padding: '8px 16px 8px 16px'
            }
        },
        MuiCardContent: {
            root: {
                padding: '0px 0px 0px 0px'
            }
        },
        MuiTableCell: {
            head: {
                color: 'white'
            }
        }
    }
});

const appVersion = '1.0.1';
GlobalApplication.currentComponent.setTitle(`Boom Bust Signals, v ${appVersion}`);
GlobalApplication.currentComponent.setSize(1280, 960);

const queryClient = new QueryClient({defaultOptions: {queries: {cacheTime: 1 * 60 * 60 * 1000, staleTime: 1 * 60 * 60 * 1000}}});

function App() {
    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <QueryClientProvider client={queryClient}>
                        <LogRoutes />
                        <GlobalDataContextProvider>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Layout>
                                    <Routes />
                                </Layout>
                            </MuiPickersUtilsProvider>
                        </GlobalDataContextProvider>
                    </QueryClientProvider>
                </BrowserRouter>
            </ThemeProvider>
        </Fragment>
    );
}

export default App;
