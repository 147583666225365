import {
    Card, CardContent, createStyles,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Theme
} from '@material-ui/core';
import { Link as LinkRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import { fetchSecuritiesFieldData } from 'services/blpData';
import { SignalLabel } from '../Shared/SignalLabel';
import { SecurityChart } from '../SecurityChart/SecurityChart';
import { useQuery } from 'react-query';
import { useGlobalDataContext } from '../../contexts/globalDataContext';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

async function fetchPrices(securities: string[]) {
    let refData = await fetchSecuritiesFieldData(
        securities,
        ['PX_LAST'],
    );

    let prices: any = {};
    for (let i = 0; i < refData.length; i++) {
        prices[refData[i].security] = refData[i].data.PX_LAST;
    }

    return prices;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: '100%',
            padding: '0px 16px 16px 16px',
            display: 'grid',
            gridTemplateRows: 'minmax(auto, 240px) 1fr',
            gridGap: '16px'
        }
    }),
);

function printCurrency(num: number | undefined) {
    if (num === undefined) {
        return '';
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    return formatter.format(num);
}

export const FacePage = observer(() => {
    const { bbSignals } = useGlobalDataContext();
    const classes = useStyles();
    const securitiesToLoad = bbSignals.map(s => s.security);
    const pricesQuery = useQuery(['pricesQuery', securitiesToLoad],
        async () => await fetchPrices(securitiesToLoad));
    const { facePageStore } = useStores();
    useEffect(() => {
        if (facePageStore.selectedSecurity === undefined) {
            facePageStore.setSelectedSecurity(bbSignals[0].security)
        }
    }, [facePageStore, bbSignals]);

    if (pricesQuery.isLoading) {
        return null;
    }

    if (!pricesQuery.isSuccess) {
        return null;
    }

    return (
        <div className={classes.container}>
            <Card>
                <CardContent style={{overflow: 'auto', maxHeight: '100%'}}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Asset</TableCell>
                                <TableCell>CURRENT SIGNAL</TableCell>
                                <TableCell width={180}>SIGNAL DATE</TableCell>
                                <TableCell width={180} align="right">SIGNAL PRICE</TableCell>
                                <TableCell width={180} align="right">CURRENT PRICE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bbSignals.map((a, idx) => {
                                const lastSignal = a.history[0];
                                return (
                                    <TableRow key={idx} selected={a.security === facePageStore.selectedSecurity} onClick={() => {facePageStore.setSelectedSecurity(a.security)}}>
                                        <TableCell>
                                            <Link
                                                component={LinkRouter}
                                                color="textPrimary"
                                                to={'/securityPage/' + a.security}>
                                                {a.security}
                                            </Link>
                                        </TableCell>
                                        <TableCell><SignalLabel signal={lastSignal?.signal}/></TableCell>
                                        <TableCell>{lastSignal?.date.format('M/D/YYYY')}</TableCell>
                                        <TableCell align="right">{printCurrency(lastSignal?.value)}</TableCell>
                                        <TableCell align="right">{printCurrency(pricesQuery.data[a.security])}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <SecurityChart securityName={facePageStore.selectedSecurity} chartId={"MainChart"} />
        </div>
    );
});
