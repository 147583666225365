import { action, makeAutoObservable, makeObservable, observable, reaction, toJS } from 'mobx';
import { SignalType } from '../apis/bbsignals';
import moment from 'moment';
import constants from '../constants';

export interface ISignalMeta {
    signal: SignalType;
    fullName: string;
    shortName: string;
    color: string;
}

class SignalMeta implements ISignalMeta {
    signal: SignalType;
    fullName: string;
    shortName: string;
    color: string;

    constructor(signal: SignalType,
                fullName: string,
                shortName: string,
                color: string) {
        this.signal = signal;
        this.fullName = fullName;
        this.shortName = shortName;
        this.color = color;
        makeAutoObservable(this);
    }
}

export enum ChartType {
    Line,
    Candle,
    OHLC
}

export enum ChartLabelType {
    ShortName,
    FullName,
}

export interface IChartSettings {
    startDate: moment.Moment | null,
    setStartDate: (newDate: moment.Moment | null) => void,
    endDate: moment.Moment | null,
    setEndDate: (newDate: moment.Moment | null) => void,
    chartType: ChartType,
    setChartType: (type: ChartType) => void,
    chartLabelType: ChartLabelType,
    setChartLabelType: (type: ChartLabelType) => void,
}

export class ChartSettings implements IChartSettings {
    startDate: moment.Moment | null = moment().startOf('day').add({years: -3});
    endDate: moment.Moment | null = moment().startOf('day');
    chartType = ChartType.Candle;
    chartLabelType = ChartLabelType.ShortName;

    constructor() {
        makeObservable(this, {
            startDate: observable,
            setStartDate: action,
            endDate: observable,
            setEndDate: action,
            chartType: observable,
            setChartType: action,
            chartLabelType: observable,
            setChartLabelType: action
        });
    }

    setStartDate(newDate: moment.Moment | null): void {
        this.startDate = newDate;
    }

    setEndDate(newDate: moment.Moment | null): void {
        this.endDate = newDate;
        if (this.startDate !== null && this.endDate !== null && this.startDate >= this.endDate) {
            this.startDate = this.endDate.clone().add({days: -1});
        }
    }

    setChartType(type: ChartType): void {
        this.chartType = type;
    }

    setChartLabelType(type: ChartLabelType): void {
        this.chartLabelType = type;
    }
}

class ApplicationStore {
    signalProperties: SignalMeta[] = [
        new SignalMeta(SignalType.Neutral, 'NEUTRAL', 'NT', '#B8BABD'),
        new SignalMeta(SignalType.Orange_BOOM, 'ORANGE BOOM', 'OB', '#F17B25'),
        new SignalMeta(SignalType.RED_BOOM, 'RED BOOM', 'RB', '#DB1920'),
        new SignalMeta(SignalType.GREEN_BUST, 'GREEN BUST', 'GB', '#22B04B'),
        new SignalMeta(SignalType.BlueBust, 'BLUE BUST', 'BB', '#008BCE'),
    ];
    globalSettings: IChartSettings = new ChartSettings();

    getSignalProperties = (signalType: SignalType): ISignalMeta => {
        const properties = this.signalProperties.find(signalProperties => signalProperties.signal === signalType);
        if (properties === undefined) {
            throw new Error('Unknown signal type');
        }
        return properties;
    }

    constructor() {
        this.loadSettings();

        makeObservable(this, {
            signalProperties: observable,
            globalSettings: observable
        });
        reaction(() => toJS(this), (json) => {
            localStorage.setItem(constants.globalSettingsKey, JSON.stringify(json));
        });
    }

    // Loads settings from saved json. Treating loaded json as current class type.
    // NOTE: Find better solution. As right now if any setting is changed it requires update loadSettings function.
    private loadSettings() {
        const str = localStorage.getItem(constants.globalSettingsKey);
        if (str === null) return;
        const json = JSON.parse(str) as ApplicationStore;
        this.globalSettings.setChartType(json.globalSettings.chartType);
        this.globalSettings.setChartLabelType(json.globalSettings.chartLabelType);

        for(let i = 0; i < this.signalProperties.length; i++) {
            const loadedSetting = json.signalProperties[i];
            const setting = this.signalProperties[i]
            if (loadedSetting !== undefined) {
                setting.shortName = loadedSetting.shortName;
                setting.fullName = loadedSetting.fullName;
            }
        }
    }
}

export const applicationStore = new ApplicationStore();

