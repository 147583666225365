import { makeObservable, observable, action } from 'mobx';

class BlpIndicatorStore {
    isConnected: boolean = false;

    constructor() {
        makeObservable(this, {
            isConnected: observable,
            setConnected: action
        });
    }

    setConnected(isConnected: boolean) {
        this.isConnected = isConnected;
    }
}

export const blpIndicatorStore = new BlpIndicatorStore();
