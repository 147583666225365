import {
    Card,
    CardContent,
    CardHeader, CircularProgress,
    Container, Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { fetchTopVideos, VideoReference } from 'apis/bbsignals';


const RenderLink = (videoReference: VideoReference, openLink: (url: string) => void) => {
    if (!videoReference.url) {
        return <>{videoReference.name}</>;
    }
    return <Link href="#" color="textSecondary" onClick={() => {
        openLink(videoReference.url);
    }} >{videoReference.name}</Link>;
}


export const TopVideos = () => {
    const videos = useQuery<VideoReference[]>('fetchTopVideos', async () => await fetchTopVideos());

    if (videos.status === 'loading') {
        return <CircularProgress />
    }

    async function openLink(url: string) {
        const config = {
            width: 800,
            height: 600,
            title: "The Boombust Signals"
        };
        await BB.Apps.App.createWindowComponent(url, config);
    }

    return <Container>
        <Card>
            <CardHeader title="Top Videos"/>
            <CardContent>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Title</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {videos.data && videos.data.map((video, idx) =>
                            <TableRow key={idx}>
                                <TableCell>{video.date}</TableCell>
                                <TableCell>{RenderLink(video, openLink)}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    </Container>
}