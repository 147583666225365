const urls = {
    managePortfolios: '/manageportfolios',
    settings: '/settings',
    topVideos: '/topVideos',
    help: '/help',
    securityPage: '/securityPage/:securityName',
    root: '/'
};

export default urls;
