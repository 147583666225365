import React from 'react';

import { ChartCanvas, Chart } from "react-stockcharts";
import {
    CandlestickSeries, LineSeries, BarSeries, OHLCSeries
} from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import {
    EdgeIndicator,
    MouseCoordinateX,
    MouseCoordinateY,
} from "react-stockcharts/lib/coordinates";

import { LabelAnnotation, Annotate } from "react-stockcharts/lib/annotation";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import { OHLCTooltip } from "react-stockcharts/lib/tooltip";
import { fitDimensions } from 'react-stockcharts/lib/helper';
import { last } from "react-stockcharts/lib/utils";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import { ChartLabelType, ChartType } from '../../stores/applicationStore';


class AnnotatedChartImpl extends React.Component {
    render() {
        const margin = { left: 60, right: 60, top: 10, bottom: 20 };
        const { data: initialData, compareChart, asset, width, height, ratio, chartType, chartLabelType, seriesName } = this.props;
        const chartAreaHeight = height - margin.top - margin.bottom;

        const signalsByDate = asset?.reduce(
            (dict, el, index) => {dict[el.date.toDate()] = el; return dict},
            {}
        );

        const compareChartByDate = compareChart?.reduce(
            (dict, el, index) => {dict[el.date.toDate()] = el.lastPrice; return dict},
            {});

        const tooltip = (signal) => {
            return `${signal.signal} - ${signal.date.format('M/D/YYYY')} - ${signal.value}`
        }

        function getAnnotationLabel(d) {
            return (chartLabelType === ChartLabelType.FullName ? signalsByDate?.[d.date].signalMeta.fullName : signalsByDate?.[d.date].signalMeta.shortName) + ' \u2BC8';
        }

        const annotationProps = {
            fontSize: 16,
            fill: d => signalsByDate?.[d.date].signalMeta.color,
            opacity: 0.8,
            text: d => getAnnotationLabel(d),
            textAnchor: 'end',
            y: ({ yScale, datum }) => yScale(datum.high),
            x: ({ xScale, xAccessor, datum }) => xScale(xAccessor(datum)) + 5,
            onClick: console.log.bind(console),
            tooltip: d => tooltip(signalsByDate?.[d.date]),
        };

        const xScaleProvider = discontinuousTimeScaleProvider
            .inputDateAccessor(d => d.date);
        const {
            data,
            xScale,
            xAccessor,
            displayXAccessor,
        } = xScaleProvider(initialData);

        const start = xAccessor(last(data));
        const end = xAccessor(data[Math.max(0, data.length - 100)]);
        const xExtents = [start + 10, end];

        const gridHeight = height - margin.top - margin.bottom;
        const gridWidth = width - margin.left - margin.right;

        const showGrid = true;
        const yGrid = showGrid ? {
            innerTickSize: -1 * gridWidth,
            tickStrokeDasharray: 'Dot',
            tickStrokeOpacity: 0.2,
            tickStrokeWidth: 1
        } : {};
        const xGrid = showGrid ? {
            innerTickSize: -1 * gridHeight,
            tickStrokeDasharray: 'Dot',
            tickStrokeOpacity: 0.2,
            tickStrokeWidth: 1
        } : {};

        return (<ChartCanvas height={height}
                             width={width}
                             ratio={ratio}
                             margin={margin}
                             seriesName={seriesName}
                             data={data}
                             xScale={xScale}
                             xAccessor={xAccessor}
                             displayXAccessor={displayXAccessor}
                             xExtents={xExtents}>

            {compareChartByDate && <Chart id={2}
                   yExtents={[d => compareChartByDate?.[d.date]]}
                   height={chartAreaHeight * 2 / 3}
                   padding={{ top: 10, bottom: 20 }}
            >
                <YAxis axisAt="left" orient="left" ticks={5} tickStroke="#FFFFFF"/>

                <LineSeries yAccessor={d => compareChartByDate[d.date]} stroke='#ffffff'/>
            </Chart>}

            <Chart id={1}
                   yExtents={[d => [d.high, d.low]]}
                   height={chartAreaHeight * 2 / 3}
                   padding={{ top: 10, bottom: 20 }}>
                <MouseCoordinateY
                    at="right"
                    orient="right"
                    displayFormat={format(".2f")} />

                <XAxis axisAt="bottom" orient="bottom" showTicks={false} stroke="#FFFFFF"/>
                <YAxis {...yGrid} axisAt="right" orient="right" ticks={5} tickStroke="#FFFFFF" />

                {chartType === ChartType.Candle && <CandlestickSeries stroke={d => d.close > d.open ? "#6BA583" : "#DB0000"}
                                                        wickStroke={d => d.close > d.open ? "#6BA583" : "#DB0000"}
                                                        fill={d => d.close > d.open ? "#6BA583" : "#DB0000"} /> }
                {chartType === ChartType.Line && <LineSeries stroke="#d3d3d3" yAccessor={d => d.close} /> }
                {chartType === ChartType.OHLC && <OHLCSeries stroke="#ffffff" /> }

                <EdgeIndicator itemType="last" orient="right" edgeAt="right" lineStroke="#ffffff"
                               yAccessor={d => d.close} fill={d => d.close > d.open ? "#6BA583" : "#FF0000"}/>

                <OHLCTooltip origin={[-40, 0]} textFill="white" fontSize={14} labelFill="orange" />

                <Annotate with={LabelAnnotation}
                          when={d => d.date in signalsByDate && signalsByDate[d.date].signal !== 'NEUTRAL'}
                          usingProps={annotationProps} />
            </Chart>

            <Chart id={3} height={ chartAreaHeight / 3 }
                   padding={{ top: 10, bottom: 10 }}
                   yExtents={d => [0, d.volume]}
                   origin={(w, h) => [0, h - chartAreaHeight / 3]}>
                <YAxis {...yGrid}  axisAt="right" orient="right" ticks={5} tickFormat={format(".0s")} stroke='#FFFFFF' tickStroke="#FFFFFF"/>
                <XAxis {...xGrid}  axisAt="bottom" orient="bottom" stroke='#FFFFFF' tickStroke="#FFFFFF" />

                <MouseCoordinateX
                    at="bottom"
                    orient="bottom"
                    displayFormat={timeFormat("%Y-%m-%d")} />
                <MouseCoordinateY
                    at="right"
                    orient="right"
                    displayFormat={format(".4s")} />

                <BarSeries yAccessor={d => d.volume} fill="#7489AB" />
            </Chart>
        </ChartCanvas>);
    }
}

export const AnnotatedChart = fitDimensions(AnnotatedChartImpl);