import _ from 'lodash';
import moment, { Moment } from 'moment';
import Papa from 'papaparse';


const sheetId = '1cWqeRdqqJTqGl9yY2umw-Zope_fl2hV9U060GAGCn2M';

export enum SignalType {
    RED_BOOM = 'RED BOOM',
    Orange_BOOM = 'ORANGE BOOM',
    Neutral = 'NEUTRAL',
    BlueBust = 'BLUE BUST',
    GREEN_BUST = 'GREEN BUST'
}

function enumerateSignalTypes() {
    return Object.values(SignalType).filter((k) => Number.isNaN(+k)) as SignalType[];
}

const upperCaseToSignalType = _.keyBy(enumerateSignalTypes(), type => type.toUpperCase());

function findSignalType(signalType: string) : null | SignalType {
    return upperCaseToSignalType[signalType?.toUpperCase()];
}

export type HistoryEntry = {
    signal: SignalType;
    date: Moment;
    value: number;
    closeValue: number | undefined;
    performance: number | undefined;
};

export type AssetType = 'Price' | 'Yield';

export type AssetDescription = {
    name: string;
    security: string;
    type: AssetType;
    history: HistoryEntry[];
};

const createAsset = (name: string, security: string, type: AssetType, entries: HistoryEntry[]): AssetDescription => ({
    name,
    security,
    type,
    history: entries
});

const createEntry = (
    signal: SignalType,
    date: Moment,
    value: number,
    closeValue?: number
): HistoryEntry => {
    const performance = closeValue !== undefined ? Math.round(Math.abs(value - closeValue) / value * 10000) / 100 : undefined;
    return { signal, date, value, closeValue, performance };
};

export type VideoReference = {
    name: string;
    url: string;
    date: string;
};

const parseNumber = (num: string | undefined) => {
    if (!num || Number.isNaN(num)) {
        return undefined;
    }
    return Number(num.replace(/[^0-9.-]+/g,""));
}

interface ISourcesRow {
    tabName: string,
    type: 'Price' | 'Yield' | 'Videos',
    security: string,
    spreadsheetId: string
}

interface ISignalRow {
    SIGNAL: string,
    DATE: string,
    'Opening Price'?: string,
    'Closing Price'?: string,
    'Opening Yield'?: string,
    'Closing Yield'?: string
}

const parsePromise = function<T>(url: string) {
    return new Promise<T[]>(function(complete, error) {
        Papa.parse(url, {complete: results => complete(results.data as T[]), error, download: true, header: true});
    });
};

const fetchIndex = async () => {
    return await parsePromise<ISourcesRow>(`https://docs.google.com/spreadsheets/d/${sheetId}/export?format=csv`);
}

export const fetchSignals = async () => {
    const result: AssetDescription[] = [];

    const indexCsv = await fetchIndex();
    // Note add Yield type
    const priceAsserts = indexCsv.filter(row => row.type === 'Price' || row.type === 'Yield');
    for (const priceAsset of priceAsserts) {
        if (!priceAsset.spreadsheetId) continue;
        const tabName = priceAsset.tabName;
        const security = priceAsset.security;

        const rows = await parsePromise<ISignalRow>(`https://docs.google.com/spreadsheets/d/${priceAsset.spreadsheetId}/export?format=csv`);
        const history: HistoryEntry[] = [];
        for (const historyRow of rows) {
            const signalType = findSignalType(historyRow.SIGNAL);
            if (signalType === null) {
                console.warn(`Unknown signalType ${historyRow.SIGNAL}`);
                continue;
            }
            const openingValueKey = priceAsset.type === 'Yield' ? 'Opening Yield' : 'Opening Price';
            const closingValueKey = priceAsset.type === 'Yield' ? 'Closing Yield' : 'Closing Price';
            const date = moment(historyRow.DATE, 'M/D/YYYY');
            if (!date.isValid()) {
                console.warn(`invalid date ${date}`);
                continue;
            }
            const price = parseNumber(historyRow[openingValueKey]);
            if (price === undefined || Number.isNaN(price)) {
                console.warn(`Unable to parse price ${price}`);
                continue;
            }

            history.push(createEntry(signalType, date, price, parseNumber(historyRow[closingValueKey])))
        }
        result.push(createAsset(tabName, security, priceAsset.type === 'Price' ? 'Price' : 'Yield', history));
    }
    console.log('fetchSignals', result);

    return _.orderBy(result, s => s.history[0]?.date.unix(), 'desc');
}

interface IVideoRow {
    Date: string,
    Title: string,
    Link: string
}

export const fetchTopVideos = async () : Promise<VideoReference[]> => {
    const result: VideoReference[] = [];

    const indexCsv = await fetchIndex();
    const videos = indexCsv.filter(row => row.type === 'Videos');

    for (const sheet of videos) {
        const rows = await parsePromise<IVideoRow>(`https://docs.google.com/spreadsheets/d/${sheet.spreadsheetId}/export?format=csv`);
        for (const row of rows) {
            result.push({date: row['Date'], name: row['Title'], url: row['Link']});
        }
    }

    return _.orderBy(result, s => moment(s.date).unix(), 'desc');
}