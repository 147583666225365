import { IconButton, Tab, Tabs } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import urls from '../services/urls';
import { Link } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useGlobalDataContext } from '../contexts/globalDataContext';
import _ from 'lodash';

export const MainTabs = () => {
    const location = useLocation();
    const history = useHistory();
    const { bbSignals } = useGlobalDataContext();
    const defaultSecurities = _.take(bbSignals.map(s => s.security), 3);
    const [securityPages, setSecurityPages] = useState(defaultSecurities);

    function removePage(page: string) {
        if (securityPages.findIndex(page => '/securityPage/' + page === location.pathname) > -1) {
            history.push(urls.root);
        }
        setSecurityPages(securityPages.filter(p => p !== page));
    }

    useEffect(() => {
        if (location.pathname.startsWith('/securityPage/')) {
            const security = location.pathname.replace('/securityPage/', '');
            if (securityPages.findIndex(page => page === security) === -1) {
                console.log('restoring pages', securityPages, security);
                setSecurityPages(curPages => [...curPages, security]);
            }
        }
    }, [securityPages, location.pathname]);

    return <Tabs indicatorColor="primary"
                 textColor="primary"
                 scrollButtons="auto"
                 variant="scrollable"
                 value={location.pathname}>
        <Tab label="All Assets" component={Link} value={urls.root} to={urls.root} />
        <Tab label="Top Videos" component={Link} value={urls.topVideos} to={urls.topVideos} />
        {securityPages.map(page =>
            <Tab key={page} component={Link} value={`/securityPage/${page}`} to={`/securityPage/${page}`}
                 label={
                     <span>
                         {page}
                         <IconButton size="small" onClick={(e) => { removePage(page); e.preventDefault(); }}>
                             <Close />
                         </IconButton>
                     </span>
                 }
            />
        )}
    </Tabs>;
}