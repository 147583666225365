import {
    Box,
    Card,
    CardContent,
    createStyles,
    List, ListItem, ListItemText,
    Theme,
    Typography
} from '@material-ui/core';
import React from 'react';
import { useParams } from "react-router-dom";
import { SignalLabel } from '../Shared/SignalLabel';
import { SecurityChart } from '../SecurityChart/SecurityChart';
import { useGlobalDataContext } from '../../contexts/globalDataContext';
import { makeStyles } from '@material-ui/core/styles';
import { AssetDescription, HistoryEntry } from '../../apis/bbsignals';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: '100%',
            padding: '0px 16px 16px 16px',
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            gridTemplateRows: 'auto 1fr',
            gridGap: '16px',
            '& > :first-child': {
                gridColumn: '1 / -1',
            }
        }
    }),
);

const signalDescription = (asset: AssetDescription, row: HistoryEntry) => {

    let result = '';
    if (asset.type === 'Price') {
        result = `Price ${row.value}`;
    } else if (asset.type === 'Yield') {
        result = `Yield ${row.value}`;
    }
    if (row.performance !== undefined && row.closeValue !== undefined) {
        result = result + ` (Performance ${row.performance}%) Low of ${row.closeValue}`;
    }
    return result;
}

export const SecurityPage = () => {
    const { securityName } = useParams<{securityName: string}>();
    const { bbSignals } = useGlobalDataContext();
    const classes = useStyles();
    const asset = bbSignals.find(s => s.security === securityName);

    return (
        <div className={classes.container}>
            <Typography color="textPrimary" variant="h5" component="h3">
                Signals for {securityName}
            </Typography>

            <Card style={{overflow: 'auto'}}>
                <CardContent>
                    <List dense>
                        {asset?.history.map((v, i) => (
                        <ListItem>
                            <ListItemText
                                primary={<Box display="flex" justifyContent="space-between"><Typography variant={'subtitle2'}>{v.date.format('M/D/YYYY')}</Typography><SignalLabel signal={v.signal}/></Box>}
                                secondary={<Typography>{signalDescription(asset, v)}</Typography>}
                            />
                        </ListItem>))}
                    </List>
                </CardContent>
            </Card>

            <SecurityChart chartId={securityName}
                           securityName={securityName} />
        </div>
    );
};
