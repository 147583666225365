import { action, makeObservable, observable } from 'mobx';

export interface IChartModel {
    chartId: string,
    compareSecurity: string,
    setCompareSecurity: (securty: string) => void
}

export class ChartModel implements IChartModel {
    compareSecurity: string = '';
    chartId: string;

    setCompareSecurity = (security: string): void => {
        this.compareSecurity = security;
    }

    constructor(chartId: string) {
        this.chartId = chartId;
        makeObservable(this, {
            compareSecurity: observable,
            setCompareSecurity: action,

        });
    }
}